// Dialog components
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  styled,
  TextField,
} from "@mui/material";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgba(255, 255, 255, 0.58)",
    backdropFilter: "blur(10px)",
    borderRadius: "12px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#333",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "16px 24px",
});

const StyledDialogContentText = styled(DialogContentText)({
  color: "#555",
});

const StyledButton = styled(Button)({
  textTransform: "uppercase",
  color: "black",
  backgroundColor: "rgba(255, 255, 255, 0.44)",
  borderRadius: "32px",
  padding: "8px 24px 8px 24px",
  border: "1px solid #888",
});
export const AlertDialog = ({ openState }) => {
  const [open, setOpen] = useState(openState);
  const handleClose = () => setOpen(false);

  return (
    <StyledDialog open={open} disableEscapeKeyDown>
      <StyledDialogTitle>{"Dzień dobry!"}</StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>
          Witaj w konfiguratorze kubków dedykowanym dla sektora B2B.
          <br />
          Pozwala on na dodanie własnej grafiki, napisu na wybranym kubku z
          naszej oferty oraz złożenie zapytania ofertowego.
          <br /> <br />
          Jeżeli potrzebujesz dodatkowej pomocy, przeczytaj instrukcję, która
          znajduje się <b>pod tym linkiem.</b> lub skontaktuj się z nami
          mailowo,{" "}
          <a style={{ color: "#555" }} href={"mailto:b2b@coffeecups.com.pl"}>
            b2b@coffeecups.com.pl
          </a>
        </StyledDialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose}>Przejdź dalej</StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export const OrderDialog = ({
  openState,
  onSubmit,
  onClose,
  cupSize,
  cupColor,
}) => {
  const [open, setOpen] = useState(openState);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    quantity: "",
    size: cupSize,
    color: cupColor,
    consent: false,
  });
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  useEffect(() => {
    const { name, email, phone, quantity, consent } = formData;
    setIsValid(
      name.trim() !== "" &&
        email.trim() !== "" &&
        phone.trim() !== "" &&
        quantity.trim() !== "" &&
        consent,
    );
  }, [formData]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(formData);
    handleClose();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledDialogTitle>{"Zamów wycenę własnego kubka"}</StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>
          Aby zamówić wycenę zaprojektowanego kubka, wypełnij poniższy
          formularz. Wszystkie pola są wymagane. Zwrotnie, w ciągu maksymalnie
          24 godzin (w dni robocze) otrzymasz wycenę dla podanej ilości i
          prognozowany czas realizacji.
        </StyledDialogContentText>
        <TextField
          required
          fullWidth
          margin="dense"
          label="Imię nazwisko"
          variant="standard"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="dense"
          label="Nazwa firmy (opcjonalnie)"
          variant="standard"
          name="company"
          value={formData.company}
          onChange={handleInputChange}
        />
        <TextField
          required
          fullWidth
          margin="dense"
          label="Adres email"
          type="email"
          variant="standard"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <TextField
          required
          fullWidth
          margin="dense"
          label="Numer telefonu"
          variant="standard"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />
        <TextField
          required
          fullWidth
          margin="dense"
          label="Ilość zamawianych sztuk"
          type="number"
          variant="standard"
          name="quantity"
          value={formData.quantity}
          onChange={handleInputChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.consent}
              onChange={handleInputChange}
              name="consent"
            />
          }
          label="Wyrażam zgodę na przetwarzanie danych osobowych"
        />
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose}>Anuluj</StyledButton>
        <StyledButton onClick={handleSubmit} disabled={!isValid}>
          Przejdź dalej
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export const SuccessDialog = ({ openState }) => {
  const [open, setOpen] = useState(openState);

  useEffect(() => {
    setOpen(openState);
  }, [openState]);
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledDialogTitle>{"Wszystko w porządku!"}</StyledDialogTitle>
      <StyledDialogContent>
        <DialogContentText>
          Twoje zapytanie zostało pomyślnie wysłane do naszego działu sprzedaży.
          <br /> <br />
          Teraz zapoznajemy się z nim, przeliczamy kubki, mierzymy i sprawdzamy
          Twój projekt tak, aby wyglądał jak najlepiej!
          <br /> <br />
          Już niedługo skontaktujemy się, aby potwierdzić zamówienie.
        </DialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose} autoFocus>
          Przejdź do strony głównej
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export const LoaderDialog = ({ openState }) => {
  const [open, setOpen] = useState(openState);

  // Sync the internal state with the prop
  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const handleClose = () => setOpen(false);

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <CircularProgress size={48} style={{ margin: 16 }} />
    </StyledDialog>
  );
};
