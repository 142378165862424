import * as THREE from "three";
export const CUP_MODEL_PATH = "./kubeczek_250.gltf";
export const CAMERA_POSITIONS = [
  [0, 0, 4],
  [0, 0, -4],
  [2, 1, 3],
  [1.4, 0.8, -3.3],
  [1, 3, 3],
];

export const FONT_OPTIONS = [
  "Arial",
  "Arial Black",
  "Verdana",
  "Times New Roman",
  "Courier",
  "Georgia",
  "Palatino",
  "Garamond",
  "Bookman",
  "Comic Sans MS",
  "Trebuchet MS",
  "Impact",
];

export const LID_MATERIAL = {
  color: "#1b1b1b",
  metalness: 0.8,
  roughness: 0.8,
  clearcoat: 0,
  clearcoatRoughness: 0,
};

export const CUP_MATERIAL = {
  metalness: 0.8,
  roughness: 0.5,
  clearcoat: 0.3,
  clearcoatRoughness: 1,
};

export const COLORS = [
  { title: "Czarny", color: "#2c2c2c" },
  { title: "Zielony", color: "#00630f" },
  { title: "Czerwony", color: "#9a3131" },
  { title: "Niebieski", color: "#31358e" },
  { title: "Srebrny", color: "#959595" },
  { title: "Złoty", color: "#af9200" },
  { title: "Turkusowy", color: "#0d9488" },
  { title: "Hot Pink", color: "#FF69B4" },
  { title: "Natural Beige", color: "#c9c5b1" },
  { title: "Lavender", color: "#9d9dcc" },
  { title: "Lilac", color: "#826387" },
  { title: "Navy Blue", color: "#000080" },
  { title: "Light Blue", color: "#57b9ff" },
  { title: "Rose Gold", color: "#ba867b" },
];

export const CUP_SIZES = [
  { value: 80, position: [0, 0, 0] },
  { value: 150, position: [1, 0, 0] },
  { value: 240, position: [2, 0, 0] },
];

export const stepCameraPositions = {
  0: new THREE.Vector3(-4, 1, 0),
  1: new THREE.Vector3(-4, 1, -3),
  2: new THREE.Vector3(0, 1, -3),
  3: new THREE.Vector3(0, 1, 3),
  4: new THREE.Vector3(0, 1, -3),
  5: new THREE.Vector3(3, 2, 2),
  6: new THREE.Vector3(3, 1.5, 1),
};
