import { useEffect, useRef } from "react";
import * as THREE from "three";

export const convertImageToWhite = (image) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const alpha = data[i + 3];
      data[i] = data[i + 1] = data[i + 2] = 255;
      data[i + 3] = alpha;
    }
    ctx.putImageData(imageData, 0, 0);
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, "image/png");
  });
};

export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = src;
  });
};

export const useTextTexture = (text, fontSize, color, fontFamily) => {
  const textureRef = useRef(
    new THREE.CanvasTexture(document.createElement("canvas")),
  );

  useEffect(() => {
    const canvas = textureRef.current.image;
    const context = canvas.getContext("2d");
    const size = 1024;
    canvas.width = size;
    canvas.height = size;

    context.clearRect(0, 0, size, size);
    context.fillStyle = "#c1c1c1";
    context.font = `${fontSize * size}px ${fontFamily}`;
    context.textAlign = "center";
    context.textBaseline = "middle";

    const lines = text.split("/");
    const lineHeight = fontSize * size * 1.2;

    lines.forEach((line, index) => {
      context.fillText(
        line,
        size / 2,
        size / 2 + (index - lines.length / 2) * lineHeight,
      );
    });

    textureRef.current.needsUpdate = true;
    textureRef.current.wrapS = THREE.ClampToEdgeWrapping;
    textureRef.current.wrapT = THREE.ClampToEdgeWrapping;
    textureRef.current.minFilter = THREE.LinearFilter;
  }, [text, fontSize, color, fontFamily]);

  return textureRef.current;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
