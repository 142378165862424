import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  AccumulativeShadows,
  Decal,
  Environment,
  PivotControls,
  RandomizedLight,
  useGLTF,
  OrbitControls,
} from "@react-three/drei";
import axios from "axios";
import { LoaderDialog, OrderDialog, SuccessDialog } from "./Dialogs";
import { EffectComposer, DepthOfField } from "@react-three/postprocessing";

import {
  CAMERA_POSITIONS,
  COLORS,
  CUP_MATERIAL,
  CUP_MODEL_PATH,
  CUP_SIZES,
  LID_MATERIAL,
  stepCameraPositions,
} from "./Constants";
import {
  convertImageToWhite,
  delay,
  loadImage,
  useTextTexture,
} from "./Helpers";

const API_URL = "https://kreator.coffeecups.com.pl/api/b2b/mail";

const DEFAULT_POS_1 = [0, 0, 0.5];
const DEFAULT_ROT_1 = [0, 0, 0];
const DEFAULT_SCALE_1 = [0.4, 0.5, 0.5];
const DEFAULT_POS_2 = [0, 0, -0.5];
const DEFAULT_ROT_2 = [0, Math.PI, 0];
const DEFAULT_SCALE_2 = [0.4, 0.5, 0.5];

const Cup = ({
  position,
  scale,
  image1,
  text1 = "",
  fontSize1,
  color1,
  toggleA = false,
  image2,
  text2 = "",
  fontSize2,
  color2,
  toggleB = false,
  cupColor,
  cover,
  fontFamily1,
  fontFamily2,
  sideType1,
  sideType2,
  onImageLoad,
  isSelected,
  step,
}) => {
  const [position1, setPosition1] = useState(DEFAULT_POS_1);
  const [rotation1, setRotation1] = useState(DEFAULT_ROT_1);
  const [scale1, setScale1] = useState(DEFAULT_SCALE_1);
  const [position2, setPosition2] = useState(DEFAULT_POS_2);
  const [rotation2, setRotation2] = useState(DEFAULT_ROT_2);
  const [scale2, setScale2] = useState(DEFAULT_SCALE_2);

  const { nodes } = useGLTF(CUP_MODEL_PATH);
  const [logoTexture1, setLogoTexture1] = useState(null);
  const [logoTexture2, setLogoTexture2] = useState(null);
  const groupRef = useRef();
  const rotationRef = useRef(0);

  useFrame((state, delta) => {
    if (groupRef.current) {
      rotationRef.current =
        step === 6
          ? rotationRef.current + delta * 0.5
          : rotationRef.current * 0.95;
      groupRef.current.rotation.y = rotationRef.current;
    }
  });

  useEffect(() => {
    const loadTexture = async (image, setText) => {
      if (image) {
        const img = await loadImage(image);
        const whiteImageUrl = await convertImageToWhite(img);
        const texture = new THREE.TextureLoader().load(whiteImageUrl, () => {
          setText(texture);
          if (onImageLoad) onImageLoad();
        });
      } else {
        setText(null);
      }
    };
    if (sideType1 === "image") loadTexture(image1, setLogoTexture1);
    if (sideType2 === "image") loadTexture(image2, setLogoTexture2);
  }, [image1, image2, sideType1, sideType2, onImageLoad]);

  const textTexture1 = useTextTexture(text1, fontSize1, color1, fontFamily1);
  const textTexture2 = useTextTexture(text2, fontSize2, color2, fontFamily2);

  const lidMaterial = useMemo(
    () => new THREE.MeshPhysicalMaterial(LID_MATERIAL),
    [],
  );
  const cupMaterial = useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: cupColor,
        emissive: isSelected ? "white" : "transparent",
        emissiveIntensity: isSelected ? 0.1 : 0,
        ...CUP_MATERIAL,
      }),
    [cupColor, isSelected],
  );

  // Handles dragging events for both pivot controls
  const handleDrag = (
    local,
    setPosition,
    setRotation,
    setScale,
    adjustZ = 0,
    right = false,
  ) => {
    const position = new THREE.Vector3();
    const scale = new THREE.Vector3();
    const quaternion = new THREE.Quaternion();
    local.decompose(position, quaternion, scale);
    const rotation = new THREE.Euler().setFromQuaternion(quaternion);
    setPosition([position.x, position.y, adjustZ + position.z]);
    setRotation([rotation.x, !right ? rotation.y : Math.PI, rotation.z]);
    setScale([scale.x, scale.y, scale.z]);
  };

  return (
    <group ref={groupRef} position={position} scale={scale}>
      {cover && (
        <mesh
          geometry={nodes.Pokrywka.geometry}
          material={lidMaterial}
          position={[0, 0.58, 0]}
          scale={0.6}
          castShadow
        />
      )}
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={nodes.Cylinder.material}
      />
      <mesh geometry={nodes.Cylinder_1.geometry} material={cupMaterial}>
        <group position={[0, 0.3, 1]}>
          <PivotControls
            visible={toggleA}
            scale={0.3}
            activeAxes={[true, true, false]}
            axisColors={["#9a3131", "#9a3131", "#9a3131"]}
            disableRotations
            autoTransform
            onDrag={(local) =>
              handleDrag(local, setPosition1, setRotation1, setScale1, 0.5)
            }
          />
          <PivotControls
            visible={toggleB}
            scale={0.3}
            activeAxes={[true, true, false]}
            axisColors={["#9a3131", "#9a3131", "#9a3131"]}
            disableRotations
            autoTransform
            offset={[0, 0.2, -2]}
            rotation={[0, Math.PI, 0]}
            onDrag={(local) =>
              handleDrag(
                local,
                setPosition2,
                setRotation2,
                setScale2,
                -0.5,
                true,
              )
            }
          />
        </group>
        {sideType1 === "image" && logoTexture1 && (
          <Decal
            position={position1}
            rotation={rotation1}
            scale={scale1}
            map={logoTexture1}
            castShadow={false}
          />
        )}
        {sideType1 === "text" && (
          <Decal
            position={position1}
            rotation={rotation1}
            scale={scale1}
            map={textTexture1}
          />
        )}
        {sideType2 === "image" && logoTexture2 && (
          <Decal
            position={position2}
            rotation={rotation2}
            scale={scale2}
            map={logoTexture2}
            castShadow={false}
          />
        )}
        {sideType2 === "text" && (
          <Decal
            position={position2}
            rotation={rotation2}
            scale={scale2}
            map={textTexture2}
          />
        )}
      </mesh>
    </group>
  );
};

const CupSize = ({ size, selectedSize, onClick }) => {
  const isSelected = size.value === selectedSize;
  return (
    <div
      style={{
        padding: "10px",
        border: isSelected ? "2px solid white" : "1px solid gray",
        cursor: "pointer",
        transition: "border 0.3s",
      }}
      onClick={() => onClick(size.value)}
    >
      {size.value} ml
    </div>
  );
};
const Stepper = ({ totalSteps, currentStep }) => {
  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          style={{
            width: index === currentStep ? "12px" : "8px",
            height: index === currentStep ? "12px" : "8px",
            background: "#fff",
            borderRadius: "50%",
            opacity: index === currentStep ? "1" : "0.5",
          }}
        ></div>
      ))}
    </div>
  );
};

const CameraAnimator = ({ camera, targetPosition, targetLookAt }) => {
  const currentLookAt = useRef(new THREE.Vector3());
  const tempVector = useRef(new THREE.Vector3());

  useFrame((state, delta) => {
    // Smoothly move camera position with faster interpolation
    camera.position.lerp(targetPosition, delta * 3);

    // Smoothly update lookAt with faster interpolation
    tempVector.current.lerp(targetLookAt, delta * 3);
    camera.lookAt(tempVector.current);

    return null;
  });

  return null;
};

// Main App component
export const App = () => {
  const [cupColor, setCupColor] = useState("#2c2c2c");
  const [cupColorName, setCupColorName] = useState("Czarny");

  const [camera, setCamera] = useState(() => {
    const cam = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    );
    cam.position.set(0, 0, 0);
    return cam;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [orderDialog, setOrderDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [targetCameraPosition, setTargetCameraPosition] = useState(
    new THREE.Vector3(-4, 1, 0),
  );
  const [targetLookAt] = useState(new THREE.Vector3(0, 0, 0));

  const [selectedCupSize, setSelectedCupSize] = useState(80); // Initial cup size

  const handleCupSelect = (size) => {
    setSelectedCupSize(size);
  };

  const canvasRef = useRef();
  const [sideType1, setSideType1] = useState("none");
  const [text1, setText1] = useState("");
  const [fontSize1, setFontSize1] = useState(0.2);
  const [fontFamily1, setFontFamily1] = useState("Arial");
  const [toggleA, setToggleA] = useState(false);

  const [image1, setImage1] = useState(null);

  const handleImage1Change = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Set the selected image to the image1 variable
      setImage1(URL.createObjectURL(file));
    }
  };

  const [sideType2, setSideType2] = useState("none");
  const [text2, setText2] = useState("");
  const [fontSize2, setFontSize2] = useState(0.2);
  const [fontFamily2, setFontFamily2] = useState("Arial");
  const [toggleB, setToggleB] = useState(false);

  const [image2, setImage2] = useState(null);

  const imageInput1 = useRef();
  const imageInput2 = useRef();

  const handleImage2Change = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage2(URL.createObjectURL(file));
    }
  };

  let color1, showSide1, color2, showSide2;
  const [cover, setCover] = useState(false);

  useEffect(() => {
    const loadModel = async () => {
      await useGLTF.preload("./kubeczek_250.gltf");
      setIsLoading(false);
    };
    loadModel();
  }, []);

  const handleStepChange = useCallback((newStep) => {
    const newPosition = stepCameraPositions[newStep];
    if (newPosition) {
      setTargetCameraPosition(newPosition);
    }
    setStep(newStep);
  }, []);

  const Navigation = () => {
    return (
      <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
        {step > 0 && (
          <button
            style={{
              background: "none",
              border: "1px solid #fff",
              borderRadius: "16px",
              padding: "8px 16px 8px 16px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              handleStepChange(step > 0 ? step - 1 : 0);
              step !== 3 && setToggleA(false);
              step !== 4 && setToggleB(false);
            }}
          >
            COFNIJ
          </button>
        )}
        <button
          style={{
            background: "none",
            border: "1px solid #fff",
            borderRadius: "16px",
            padding: "8px 16px 8px 16px",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            step !== 6 ? handleStepChange(step + 1) : setOrderDialog(true);
            step !== 3 && setToggleA(false);
            step !== 4 && setToggleB(false);
          }}
        >
          DALEJ
        </button>
      </div>
    );
  };

  const takeScreenshot = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const cropWidth = 3840;
    const cropHeight = 2160;
    const cropX = (canvas.width - cropWidth) / 2;
    const cropY = (canvas.height + 256 - cropHeight) / 2;

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = cropWidth;
    tempCanvas.height = cropHeight;
    const tempCtx = tempCanvas.getContext("2d");

    tempCtx.drawImage(
      canvas,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );

    const watermarkImg = await loadImage("./logo.svg");
    tempCtx.globalAlpha = 0.3;
    tempCtx.drawImage(
      watermarkImg,
      cropWidth / 2 - 50,
      cropHeight - 100,
      100,
      80,
    );

    return new Promise((resolve) => {
      tempCanvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  const saveScreenshot = async (formData) => {
    setIsLoading(true);

    try {
      const apiFormData = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        apiFormData.append(key, value);
      }

      const imagesToSend = [];

      // Add uploaded images to the array if they exist
      if (image1) {
        const response = await fetch(image1);
        const blob = await response.blob();
        imagesToSend.push({ blob, name: "Logo 1" });
      }
      if (image2) {
        const response = await fetch(image2);
        const blob = await response.blob();
        imagesToSend.push({ blob, name: "Logo 2" });
      }

      // Take screenshots and add them to the array
      for (let i = 3; i <= 6; i++) {
        handleStepChange(i);
        await delay(1500);
        const blob = await takeScreenshot();
        imagesToSend.push({ blob, name: `Kubek ${i + 1}` });
      }

      // Append all images to the form data
      imagesToSend.forEach((img, index) => {
        apiFormData.append("images[]", img.blob, img.name);
      });

      const response = await axios.post(API_URL, apiFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Success:", response.data);
      setIsLoading(false);
      setSuccessDialog(true);
    } catch (error) {
      setIsLoading(false);
      console.error("Error taking screenshot:", error);
    }
  };

  const [shouldTriggerInput1, setShouldTriggerInput1] = useState(false);
  const [shouldTriggerInput2, setShouldTriggerInput2] = useState(false);

  useEffect(() => {
    if (shouldTriggerInput1 && imageInput1.current) {
      imageInput1.current.click();
      setShouldTriggerInput1(false);
    }
  }, [shouldTriggerInput1]);

  useEffect(() => {
    if (shouldTriggerInput2 && imageInput2.current) {
      imageInput2.current.click();
      setShouldTriggerInput2(false);
    }
  }, [shouldTriggerInput2]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <img
        src={"./logo.svg"}
        style={{
          position: "absolute",
          zIndex: "9",
          width: "100px",
          top: "16px",
          left: "16px",
          opacity: "0.5",
        }}
        alt="Logo"
      />
      <div style={{ flexGrow: 1 }}>
        <Canvas
          ref={canvasRef}
          gl={{ preserveDrawingBuffer: true }}
          shadows
          camera={camera}
          style={{ background: "#000", width: "100%", height: "100%" }}
        >
          <CameraAnimator
            camera={camera}
            targetPosition={targetCameraPosition}
            targetLookAt={targetLookAt}
          />
          {step === 0 && (
            <group>
              <Cup
                position={[0, 0, -1.3]}
                scale={[0.8, 0.8, 0.8]}
                cupColor={"#00630f"}
              />
              <Cup
                position={[-0.3, 0, -0.2]}
                scale={[1, 1, 1]}
                cupColor={"#31358e"}
                cover={true}
              />
              <Cup
                position={[-0.5, 0, 1.1]}
                scale={[1.5, 1.5, 1.5]}
                cupColor={"#9a3131"}
              />
            </group>
          )}
          {step === 1 && (
            <group>
              <Cup
                position={[0, 0, -1.3]}
                scale={[0.8, 0.8, 0.8]}
                cupColor={"#00630f"}
                isSelected={selectedCupSize === 80}
              />
              <Cup
                position={[-0.3, 0, -0.3]}
                scale={[1, 1, 1]}
                cupColor={"#31358e"}
                isSelected={selectedCupSize === 150}
              />
              <Cup
                position={[-0.5, 0, 1.1]}
                scale={[1.5, 1.5, 1.5]}
                cupColor={"#9a3131"}
                isSelected={selectedCupSize === 240}
              />
            </group>
          )}
          {step > 1 && (
            <Cup
              image1={image1}
              text1={text1}
              fontSize1={fontSize1}
              image2={image2}
              text2={text2}
              fontSize2={fontSize2}
              toggleA={toggleA}
              toggleB={toggleB}
              cupColor={cupColor}
              cover={cover}
              fontFamily1={fontFamily1}
              fontFamily2={fontFamily2}
              sideType1={sideType1}
              sideType2={sideType2}
              scale={[1.5, 1.5, 1.5]}
              step={step}
            />
          )}
          <AccumulativeShadows
            temporal
            frames={100}
            alphaTest={0.95}
            opacity={1}
            scale={10}
            position={[0, -1, 0]}
          >
            <RandomizedLight
              amount={5}
              radius={3}
              ambient={0.5}
              position={[10, 10, 3]}
              bias={0.05}
              size={10}
            />
          </AccumulativeShadows>
          <Environment
            files={"./blue_photo_studio_4k.hdr"}
            blur={"100px"}
            background={true}
          />
          <EffectComposer>
            <DepthOfField
              focusDistance={0}
              focalLength={1}
              bokehScale={4}
              height={256}
            />
          </EffectComposer>
          <OrbitControls makeDefault />
        </Canvas>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 32,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Arial",
          color: "white",
          gap: "16px",
        }}
      >
        {step === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "1.48rem" }}>
              Stwórz swój firmowy kubek!
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Stepper currentStep={step} totalSteps={7} />
              </div>
              <div style={{ display: "flex", gap: "16px" }}>
                <Navigation />
              </div>
            </div>
          </div>
        )}
        {step === 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "1.48rem" }}>Wybierz rozmiar kubka</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: "16px" }}>
                {CUP_SIZES.map((size) => (
                  <CupSize
                    key={size.value}
                    size={size}
                    selectedSize={selectedCupSize}
                    onClick={handleCupSelect}
                  />
                ))}
              </div>
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Stepper currentStep={step} totalSteps={7} />
              </div>
              <div style={{ display: "flex", gap: "16px" }}>
                <Navigation />
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "8px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "1.48rem" }}>Wybierz kolor kubka</span>
              <span style={{ fontSize: "1rem" }}>{cupColorName}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  maxWidth: "320px",
                  flexWrap: "wrap",
                }}
              >
                {COLORS.map((color, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        background:
                          "linear-gradient(#ffffff -100%, " +
                          color.color +
                          " 50%)",
                        width: "32px",
                        height: "32px",
                        border: cupColor === color.color && "2px solid #ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCupColor(color.color);
                        setCupColorName(color.title);
                      }}
                    ></div>
                  );
                })}
              </div>
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Stepper currentStep={step} totalSteps={7} />
              </div>
              <div style={{ display: "flex", gap: "16px" }}>
                <Navigation />
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "1.48rem" }}>
              Personalizuj pierwszą stronę
            </span>

            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <button
                style={{
                  backgroundColor:
                    sideType1 === "text" ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSideType1("text");
                  setToggleA(true);
                }}
              >
                TEKST
              </button>
              <button
                style={{
                  backgroundColor:
                    sideType1 === "image" ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSideType1("image");
                  setShouldTriggerInput1(true);
                  setToggleA(true);
                }}
              >
                OBRAZEK
              </button>
              <button
                style={{
                  backgroundColor:
                    sideType1 === "none" ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSideType1("none");
                  setToggleA(false);
                }}
              >
                BRAK
              </button>
            </div>
            {sideType1 === "text" && (
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <div
                  style={{
                    position: "fixed",
                    top: 16,
                    left: 128,
                    display: text1.length !== 0 ? "flex" : "none",
                    width: "100%",
                    fontSize: "1rem",
                  }}
                >
                  Chwyć za czerwony kwadrat, aby przesunąć tekst
                </div>
                <input
                  placeholder={"Tutaj wpisz swój tekst"}
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    padding: "8px 16px 8px 16px",
                    width: "180px",
                    height: "36px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  value={text1}
                  onChange={(event) => setText1(event.target.value)}
                ></input>
                <select
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    padding: "8px 16px 8px 16px",
                    width: "180px",
                    height: "36px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onChange={(event) => setFontFamily1(event.target.value)}
                  value={fontFamily1}
                >
                  <option value="Arial">Arial</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Impact">Impact</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Lucida Console">Lucida Console</option>
                </select>
                <div className="slider-container">
                  <input
                    type="range"
                    min="0.1"
                    max="0.5"
                    step="0.025"
                    value={fontSize1}
                    className={"slider"}
                    onChange={(event) =>
                      setFontSize1(Number(event.target.value))
                    }
                  />
                </div>
              </div>
            )}
            {sideType1 === "image" && (
              <input
                ref={imageInput1}
                type="file"
                accept="image/*"
                onChange={handleImage1Change}
                style={{
                  position: "fixed",
                  bottom: "-100%",
                  visibility: "hidden",
                }}
              />
            )}
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Stepper currentStep={step} totalSteps={7} />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Navigation />
            </div>
          </div>
        )}
        {step === 4 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "1.48rem" }}>
              Personalizuj drugą stronę
            </span>

            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <button
                style={{
                  backgroundColor:
                    sideType2 === "text" ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSideType2("text");
                  setToggleB(true);
                }}
              >
                TEKST
              </button>
              <button
                style={{
                  backgroundColor:
                    sideType2 === "image" ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSideType2("image");
                  setShouldTriggerInput2(true);
                  setToggleB(true);
                }}
              >
                OBRAZEK
              </button>
              <button
                style={{
                  backgroundColor:
                    sideType2 === "none" ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSideType2("none");
                  setToggleB(false);
                }}
              >
                BRAK
              </button>
            </div>
            {sideType2 === "text" && (
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <div
                  style={{
                    position: "fixed",
                    top: 16,
                    left: 128,
                    display: "flex",
                    width: "100%",
                    fontSize: "1rem",
                  }}
                >
                  Chwyć za czerwony kwadrat, aby przesunąć tekst
                </div>
                <input
                  placeholder={"Tutaj wpisz swój tekst"}
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    padding: "8px 16px 8px 16px",
                    width: "180px",
                    height: "36px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  value={text2}
                  onChange={(event) => setText2(event.target.value)}
                ></input>
                <select
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    padding: "8px 16px 8px 16px",
                    width: "180px",
                    height: "36px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onChange={(event) => setFontFamily2(event.target.value)}
                  value={fontFamily1}
                >
                  <option value="Arial">Arial</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Impact">Impact</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Lucida Console">Lucida Console</option>
                </select>
                <div className="slider-container">
                  <input
                    type="range"
                    min="0.1"
                    max="0.5"
                    step="0.025"
                    value={fontSize2}
                    className={"slider"}
                    onChange={(event) =>
                      setFontSize2(Number(event.target.value))
                    }
                  />
                </div>
              </div>
            )}
            {sideType2 === "image" && (
              <input
                ref={imageInput2}
                type="file"
                accept="image/*"
                onChange={handleImage2Change}
                style={{
                  position: "fixed",
                  bottom: "-100%",
                  visibility: "hidden",
                }}
                hidden
              />
            )}
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Stepper currentStep={step} totalSteps={7} />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Navigation />
            </div>
          </div>
        )}
        {step === 5 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "1.48rem" }}>Czy dodawać pokrywkę?</span>

            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <button
                style={{
                  backgroundColor: cover ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCover(true);
                }}
              >
                TAK
              </button>
              <button
                style={{
                  backgroundColor: !cover ? "#212121" : "transparent",
                  border: "1px solid #fff",
                  padding: "8px 16px 8px 16px",
                  width: "100px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCover(false);
                }}
              >
                BRAK
              </button>
            </div>

            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Stepper currentStep={step} totalSteps={7} />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Navigation />
            </div>
          </div>
        )}
        {step === 6 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "1.48rem" }}>
              Czy tak wygląda Twój nowy kubek?
            </span>

            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Stepper currentStep={step} totalSteps={7} />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Navigation />
            </div>
          </div>
        )}
      </div>
      <OrderDialog
        openState={orderDialog}
        onSubmit={saveScreenshot}
        onClose={() => setOrderDialog(false)}
        cupSize={selectedCupSize}
        cupColor={cupColorName}
      />
      <SuccessDialog openState={successDialog} />
      <LoaderDialog openState={isLoading} />
    </div>
  );
};
